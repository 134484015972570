import React, { useState, useEffect, useRef, useContext } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import styled from "styled-components";
import Link from "next/link";
import contactNumber from "utils/contactNumber";
import {
  withAuth,
  getLoginUrl,
  triggerLogout,
  getSignupUrl,
} from "@usn/atlas-auth";
import Button from "components/Button";
import Badge from "components/Badge";
import Heart from "public/icons/heart-o.svg";
import { FavoritingContext } from "utils/favoritingContext";
import Close from "public/icons/close.svg";
import Hamburger from "public/icons/hamburger.svg";
import Logo from "public/logos/GoToSea.svg";
import Search from "public/icons/search.svg";
import USNLogo from "public/logos/USNews.svg";
import {
  white00,
  black70,
  gray05,
  gray10,
  gray50,
  green60,
} from "styles/colors";
import fontSizes from "styles/fontSizes";
import media from "styles/media";
import TextInput from "components/formControls/TextInput";
import PrivacyChoicesButton from "components/PrivacyChoicesButton";
import { zOffCanvasOpen } from "styles/z-index";
import paths from "utils/paths";
import getAPIQuery from "utils/getAPIQuery";
import spacing from "styles/spacing";
import Accordion from "./Accordion";
import NavSection from "./NavSection";
import Autosuggest from "./Autosuggest";
import getSearchItems from "./getSearchItems";

// TO DO -> extract out simple components
const Container = styled.div`
  // z-index so sidebar will cover other page elements
  // when it transitions in
  z-index: ${zOffCanvasOpen};
  position: relative;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

const HeaderBar = styled.div`
  flex-grow: 1;
  background-color: ${white00};
  box-shadow: rgba(51, 51, 51, 0.1) 0px 8px 10px;
  ${(props) =>
    props.$hideBorderShadow &&
    `
    border-bottom: 1px solid ${gray10};
    box-shadow: none;
  `}
  border-bottom: 1px solid ${gray10};
  padding: 0rem 1rem;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const NavContainer = styled.div`
  display: flex;
`;

const SidebarContainer = styled.div`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
`;

const CloseIcon = styled.button`
  --webkit-appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  left: 7px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
    outline: none;
    background-size: 2.5rem;
    background-color: rgba(102, 102, 102, 0.1);
  }
  :focus {
    cursor: pointer;
    outline: none;
    background-size: 2.5rem;
    background-color: rgba(102, 102, 102, 0.1);
  }
`;

const SidebarMenu = styled.nav`
  width: 292px;
  visibility: hidden;
  position: absolute;
  right: -350px;
  height: calc(100vh - 32px);
  top: 0;
  box-shadow: rgba(51, 51, 51, 0.1) 0px 8px 10px;
  padding: 2rem 0rem;
  padding-top: 0px;
  background-color: ${white00};
  transition: 0.3s;
  pointer-events: none;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  ${({ $isActive }) =>
    $isActive &&
    `
    right: 0;
    pointer-events: all;
    visibility: visible;
  `}
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  ${fontSizes[0]}
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${white00};
  align-items: center;
  padding: 0rem 1rem;
  flex-shrink: 0;
  background-color: ${green60};
  height: 52px;
`;

const MenuOption = styled.div`
  display: flex;
  justify-content: space-between;
  ${fontSizes[1]}
  font-weight: bold;
  color: ${black70};
  align-items: center;
  padding: 0rem 1rem;
  flex-shrink: 0;
  background-color: ${white00};
  height: 52px;
  cursor: pointer;
  :hover {
    outline: none;
    background-color: ${gray05};
    cursor: pointer;
  }
  > div {
    display: flex;
  }
`;

const Terms = styled.div`
  margin-top: auto;
  padding: 0 1rem;
`;

const Copyright = styled.div`
  ${fontSizes[0]}
  font-weight: 400;
  color: ${black70};
  margin-bottom: 0.5rem;
  margin-top: 2rem;
`;

const TermLinks = styled.div`
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  a {
    -moz-appearance: none;
    -webkit-appearance: none;
    text-decoration: none;
    ${fontSizes[0]}
    font-weight: 400;
    color: ${gray50};
    text-align: center;
    margin-bottom: 2rem;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    :hover {
      color: ${black70};
    }
    :focus {
      outline: rgb(178, 220, 255) auto 1px;
    }
  }
  -moz-appearance: none;
  -webkit-appearance: none;
  text-decoration: none;
  ${fontSizes[0]}
  font-weight: 400;
  color: ${gray50};
`;

const USNBranding = styled.div`
  background-color: ${gray05};
  margin: -0.5rem 1rem 0 -1rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  a,
  a:hover,
  a:visited {
    text-decoration: none;
    color: ${gray50};
  }
`;

const USNBrandingText = styled.div`
  ${fontSizes[0]}
  color: ${gray50};
  margin-bottom: 2px;
`;

const GTSLogo = styled(Logo)`
  height: 21px;
  width: auto;
  @media screen and (min-width: 360px) {
    height: 28px;
  }
`;

const LogoAnchor = styled(Link)`
  align-items: center;
  display: flex;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  :focus {
    outline: solid 4px rgb(178, 220, 255);
  }
  /* margin-top: 16px;
  margin-bottom: 16px; */
  :hover {
    cursor: pointer;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  ${media.medium`
    flex: 1;
  `}
  margin-left: 1rem;
`;

const ContactLabel = styled.div`
  ${fontSizes[0]}
  color: ${black70};
  font-weight: 400;
  text-align: right;
`;

const ContactNumber = styled.div`
  ${fontSizes[3]}
  color: ${black70};
  font-weight: 700;
  text-align: right;
`;

const ContactBox = styled.div`
  display: none;
  height: 100%;
  margin-left: auto;
  margin-right: 0.5rem;
  @media screen and (min-width: 1210px) {
    opacity: 0;
    position: absolute;
    right: ${(props) => (!props.$loggedIn ? "18.6rem" : "9.6rem")};
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    ${({ $active }) =>
      $active &&
      `
      opacity: 1;
    `}
  }
  transition:
    all 2s ease,
    right 0;
`;

const ContactInfo = ({ active, loggedIn }) => {
  return (
    <ContactBox $active={active} $loggedIn={loggedIn}>
      <ContactLabel>Talk to our cruise advisors</ContactLabel>
      <ContactNumber>{contactNumber}</ContactNumber>
    </ContactBox>
  );
};

ContactInfo.propTypes = {
  active: PropTypes.bool,
  loggedIn: PropTypes.bool,
};

const OverflowContainer = styled.div`
  overflow: hidden;
  margin: 0;
  flex: 1;
  width: 0;
  max-width: 64px;
  position: relative;
  align-self: center;
  opacity: 0;
  right: -2px;
  ul {
    display: none;
  }
  ${({ $active }) =>
    $active &&
    `
    opacity: 1;
    overflow: visible;
    width: auto;
    max-width: 470px;
    padding: 5px;
  `}
  ${media.medium`
    transition: all 0.5s ease, opacity 1s ease;
    ul {
      display: block;
    }
  `}
`;

const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

const SearchIcon = styled.div`
  position: absolute;
  right: ${(props) => (!props.$loggedIn ? "16rem" : "7rem")};
  z-index: 0;
  display: none;
  --webkit-appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  align-self: center;
  :hover {
    cursor: pointer;
    outline: none;
    background-size: 2.5rem;
    background-color: rgba(102, 102, 102, 0.1);
  }
  :focus {
    cursor: pointer;
    outline: none;
    background-size: 2.5rem;
    background-color: rgba(102, 102, 102, 0.1);
  }
  ${media.medium`
    display: flex;
  `}
`;

const BadgeContainer = styled(SearchIcon)`
  position: static;
  display: flex;
  ${media.medium`
    margin-right: 11px;
    margin-left: initial;
  `}
`;

const LoginButton = styled(Button)`
  z-index: 0;
  display: none;
  --webkit-appearance: none;
  border: none;
  outline: none;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right: 10px;
  ${media.medium`
    display: flex;
  `}
`;

const LoginContainer = styled.div`
  cursor: pointer;
  :hover,
  :focus {
    cursor: pointer;
  }
`;

const SavedFavoritesContainer = styled.div`
  margin-top: 2px;
  margin-left: ${spacing[2]};
  height: 15px;
  width: 15px;
  border-radius: 10px;
  background-color: ${green60};
  ${fontSizes[0]};
  font-weight: bold;
  letter-spacing: 0;
  color: ${white00};
  text-align: center;
`;

const LargeInput = styled.div`
  display: flex;
  flex: 1;
`;

const StyledTextInput = styled(TextInput)`
  flex: 1;
  margin-top: 0;
  width: 0;
  label {
    opacity: 0;
    ${({ $active }) =>
      $active &&
      `
      opacity: 1;
    `}
    transition: all 0.25s ease;
  }
  input {
    min-width: 0;
  }
`;

const HamburgerIcon = styled.button`
  --webkit-appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  :hover {
    cursor: pointer;
    outline: none;
    background-size: 2.5rem;
    background-color: rgba(102, 102, 102, 0.1);
  }
  :focus {
    cursor: pointer;
    outline: none;
    background-size: 2.5rem;
    background-color: rgba(102, 102, 102, 0.1);
  }
  margin-left: auto;
  ${media.medium`
    margin-left: 0;
  `}
`;

const Divider = styled.div`
  border-bottom: 1px solid ${gray10};
`;

const SignIn = styled.a`
  display: none;
  ${media.medium`
    text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-right: ${spacing[4]};
    ${fontSizes[1]}
    cursor: pointer;
    color: ${black70};
  `}
`;

const Header = ({ loggedIn, user, items, hideBorderShadow }) => {
  const router = useRouter();
  const [activeWidget, setActiveWidget] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [savedFavoritesCount, setSavedFavoritesCount] = useState(0);
  const { favoritedSailingIds } = useContext(FavoritingContext) || [];

  const { cruiselines, departure_ports, destinations } = items;
  const cruiseNav = {
    label: "Cruise Lines",
    items: cruiselines.map((cruiseline) => {
      const { name, id } = cruiseline;
      const link =
        paths.search().path +
        getAPIQuery({ filters: { cruiselineIds: [id] }, sort: {} });
      return { label: name, link };
    }),
  };
  const destinationNav = {
    label: "Destinations",
    items: destinations.map((destination) => {
      const { name, canned_search_urlname } = destination;
      const link = paths.cannedSearch(canned_search_urlname).path;
      return { label: name, link };
    }),
  };
  const portNav = {
    label: "Ports",
    items: departure_ports.map((port) => {
      const { name, state, country, canned_search_urlname } = port;
      const portName = `${name}, ${state ? state.name : country.name}`;
      const link = paths.cannedSearch(canned_search_urlname).path;
      return { label: portName, link };
    }),
  };

  const lastMinuteNav = {
    label: "Last Minute Deals",
    link: "/last-minute-cruise-deals",
  };

  const navItems = [destinationNav, cruiseNav, portNav, lastMinuteNav];

  const matches = getSearchItems({
    ports: departure_ports,
    destinations,
    text: searchValue,
  });
  const activeSidebar = activeWidget === "sidebar";
  const activeSearch = activeWidget === "search";
  const sidebarRef = useRef(null);
  const largeSearchRef = useRef(null);
  const largeTextInputRef = useRef(null);
  // close widgets on outside click/enter press
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        activeSidebar &&
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target)
      ) {
        setActiveWidget(false);
      } else if (
        activeSearch &&
        largeSearchRef.current &&
        !largeSearchRef.current.contains(e.target)
      ) {
        setActiveWidget(false);
      }
    };
    const handleEnterPressOutside = (e) => {
      if (
        activeSidebar &&
        e.keyCode === 27 &&
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target)
      ) {
        setActiveWidget(false);
      } else if (
        activeSearch &&
        e.keyCode === 27 &&
        largeTextInputRef.current &&
        !largeTextInputRef.current.contains(e.target)
      ) {
        setActiveWidget(false);
      }
    };
    // Bind the event listener for outside click
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEnterPressOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEnterPressOutside);
    };
  }, [sidebarRef, activeSidebar, activeSearch, largeTextInputRef]);

  // navigate autosuggest matches with up/down keys
  const handleTextInputKeyDown = (e) => {
    // up arrow
    if (e.keyCode === 38) {
      if (activeMenuItem === null) {
        setActiveMenuItem(matches.length - 1);
      } else if (activeMenuItem === 0) {
        setActiveMenuItem(null);
      } else {
        setActiveMenuItem(activeMenuItem - 1);
      }
    }
    // down arrow
    if (e.keyCode === 40) {
      if (activeMenuItem === null) {
        setActiveMenuItem(0);
      } else if (activeMenuItem === matches.length - 1) {
        setActiveMenuItem(null);
      } else {
        setActiveMenuItem(activeMenuItem + 1);
      }
    }
    // enter button, navigate to link
    if (e.keyCode === 13 && matches.length) {
      window.location.href = matches[activeMenuItem].link;
    }
  };
  const handleTextInputOnChange = (input) => {
    setSearchValue(input);
  };
  useEffect(() => {
    if (matches.length) {
      setActiveMenuItem(0);
    } else {
      setActiveMenuItem(null);
    }
  }, [searchValue]);

  const handleSearchClick = () => {
    setActiveWidget("search");
  };

  const onLoginClick = (e) => {
    e.preventDefault();
    router.push(getLoginUrl());
  };

  const onSignUpClick = (e) => {
    e.preventDefault();
    router.push(getSignupUrl());
  };

  const onBadgeClick = () => {
    window.location.href = paths.favorites().path;
  };

  const onMyCruiseBookingClick = () => {
    window.location.href = paths.myCruiseBookings().path;
  };

  const onManagePreferencesClick = () => {
    window.location.href = paths.communicationPreferences().path;
  };

  const onCustomerReviewsClick = () => {
    window.location.href = "/customer-reviews";
  };

  useEffect(() => {
    setSavedFavoritesCount(favoritedSailingIds?.length);
  }, [favoritedSailingIds]);

  useEffect(() => {
    if (activeWidget !== "search") {
      return;
    }
    if (largeTextInputRef.current.style.display !== "none") {
      largeTextInputRef.current.focus();
    }
  }, [activeWidget]);

  const today = new Date();

  return (
    <Container>
      <HeaderBar $hideBorderShadow={hideBorderShadow}>
        <NavContainer>
          <USNBranding>
            <a href="https://travel.usnews.com/cruises/">
              <USNBrandingText>A service of</USNBrandingText>
              <USNLogo
                width="85"
                height="21"
                alt="U.S. News and World Report"
              />
            </a>
          </USNBranding>
          <LogoAnchor href={paths.home().path} tabIndex="0">
            <GTSLogo width="300" height="55" alt="Go To Sea" />
          </LogoAnchor>
          <NavSection navItems={navItems} />
        </NavContainer>

        <Controls>
          <ContactInfo active={!activeSearch} loggedIn={loggedIn} />
          <OverflowContainer $active={activeSearch}>
            {!activeSidebar && (
              <div ref={largeSearchRef}>
                <SearchContainer>
                  <LargeInput>
                    <StyledTextInput
                      id="search-destinations"
                      $active={activeSearch}
                      value={searchValue}
                      onChange={handleTextInputOnChange}
                      label="Search Destinations"
                      onKeyDown={handleTextInputKeyDown}
                      inputRef={largeTextInputRef}
                      disabled={!activeSearch}
                    />
                  </LargeInput>
                </SearchContainer>
                {matches.length > 0 && (
                  <Autosuggest
                    items={matches}
                    activeMenuItem={activeMenuItem}
                    setActiveMenuItem={setActiveMenuItem}
                  />
                )}
              </div>
            )}
          </OverflowContainer>

          <SearchIcon
            onClick={handleSearchClick}
            role="button"
            $loggedIn={loggedIn}
          >
            {!activeWidget ? (
              <Search height="26px" width="26px" fill={gray50} />
            ) : (
              <Close fill={gray50} height="26px" width="26px" />
            )}
          </SearchIcon>

          {!activeSidebar && (
            <BadgeContainer role="button" onClick={onBadgeClick}>
              <Badge count={savedFavoritesCount}>
                <Heart height="33px" width="33px" fill={gray50} />
              </Badge>
            </BadgeContainer>
          )}

          {!activeSidebar && !loggedIn && (
            <>
              <SignIn onClick={onLoginClick}>Sign In</SignIn>
              <LoginButton onClick={onSignUpClick} small role="button">
                Sign Up
              </LoginButton>
            </>
          )}

          {!activeSidebar && (
            <HamburgerIcon
              role="button"
              onClick={() => {
                setActiveWidget("sidebar");
                setSearchValue("");
              }}
            >
              <Hamburger height="28px" width="28px" fill={gray50} />
            </HamburgerIcon>
          )}
        </Controls>
      </HeaderBar>
      <SidebarContainer>
        <SidebarMenu $isActive={activeSidebar} ref={sidebarRef}>
          <SidebarHeader>
            {loggedIn ? (
              `Hello, ${
                user.attributes.given_name
                  ? user.attributes.given_name
                  : user.attributes.email
              }`
            ) : (
              <LoginContainer onClick={onSignUpClick}> Sign Up </LoginContainer>
            )}
            <CloseIcon
              onClick={() => {
                setActiveWidget(false);
              }}
              role="button"
            >
              <Close fill={white00} height="26px" width="26px" />
            </CloseIcon>
          </SidebarHeader>
          {!loggedIn && (
            <>
              <MenuOption onClick={onLoginClick}>Sign In</MenuOption>
              <Divider />
            </>
          )}
          {loggedIn && (
            <div>
              <MenuOption onClick={onMyCruiseBookingClick}>
                My Cruise Bookings
              </MenuOption>
              <MenuOption onClick={onBadgeClick}>
                <div>
                  Saved Sailings
                  {savedFavoritesCount > 0 && (
                    <SavedFavoritesContainer>
                      {" "}
                      {savedFavoritesCount.toString()}{" "}
                    </SavedFavoritesContainer>
                  )}
                </div>
              </MenuOption>
              <Divider />
            </div>
          )}
          {navItems.map((item) => (
            <Accordion
              key={item.label}
              items={item.items}
              label={item.label}
              link={item.link}
            />
          ))}
          <MenuOption onClick={onCustomerReviewsClick}>
            Customer Reviews
          </MenuOption>
          <Divider />
          {!loggedIn && (
            <MenuOption onClick={onMyCruiseBookingClick}>
              My Cruise Bookings
            </MenuOption>
          )}
          {loggedIn && (
            <div>
              <MenuOption onClick={onManagePreferencesClick}>
                Manage Preferences
              </MenuOption>
              <MenuOption onClick={() => triggerLogout()}>Sign Out</MenuOption>
            </div>
          )}
          <Terms>
            <Copyright>Copyright {today.getFullYear()} © GoToSea</Copyright>
            <TermLinks>
              <a href={paths.userAgreement().path}>User Agreement</a>/
              <a href={paths.privacyPolicy().path}>Privacy Policy</a>
            </TermLinks>
            <PrivacyChoicesButton />
          </Terms>
        </SidebarMenu>
      </SidebarContainer>
    </Container>
  );
};

Header.propTypes = {
  loggedIn: PropTypes.bool,
  user: PropTypes.object,
  hideBorderShadow: PropTypes.bool,
  items: PropTypes.shape({
    cruiselines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    departure_ports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        canned_search_urlname: PropTypes.string,
      })
    ),
    destinations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        canned_search_urlname: PropTypes.string,
      })
    ),
  }),
};

export default withAuth(Header);
