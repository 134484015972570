/* Base */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
/* Utilities */
import { HEADER_PROPS_FROM_API } from "constants/propTypes";
/* Components */
import Header from "components/Header";
import Footer from "components/Footer";

const PageContainer = styled.div`
  min-width: 375px;
`;

const PageWrapper = ({
  children,
  items,
  shadowlessHeader = false,
  className = "",
}) => (
  <PageContainer className={className}>
    <Header items={items} hideBorderShadow={shadowlessHeader} />
    {children}
    <Footer />
  </PageContainer>
);

PageWrapper.propTypes = {
  children: PropTypes.node,
  items: HEADER_PROPS_FROM_API,
  shadowlessHeader: PropTypes.bool,
  className: PropTypes.string,
};

export default PageWrapper;
