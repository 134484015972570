const filterConversion = {
  destinations: "destination",
  ports: "departure_port",
  startDate: "start_date_after",
  endDate: "start_date_before",
  minPrice: "price_min",
  maxPrice: "price_max",
  durations: "durations",
  cruiselineIds: "ship__cruiseline_id",
  roomTypes: "room_types",
  instantBooking: "instant_booking",
  duration: "duration",
  shipIds: "ship",
  details: "details",
};

const getSort = ({ criteria, direction }) => {
  let sortString = "&o=";
  if (direction === "descending") {
    sortString += "-";
  }
  switch (criteria) {
    case "line":
      sortString += "ship__cruiseline__name";
      break;
    case "date":
      sortString += "start_date";
      break;
    case "inside":
      sortString += "inside_price";
      break;
    case "ocean":
      sortString += "outside_price";
      break;
    case "balcony":
      sortString += "balcony_price";
      break;
    case "lowest":
      sortString += "lowest_price";
      break;
    case "recommended":
      sortString += "recommended";
      break;
    case "price_drops":
      sortString += "price_drops";
      break;
    default:
      break;
  }
  return sortString;
};

const getAPIQuery = ({ filters, sort }) => {
  let queryString = "";
  Object.keys(filters)
    .filter((key) => {
      if (
        !filters[key] ||
        (typeof filters[key] === "object" && filters[key].length === 0)
      ) {
        return false;
      }
      return true;
    })
    .forEach((key) => {
      const value = filters[key];
      if (
        key !== "duration" &&
        key !== "cruiselineIds" &&
        key !== "ports" &&
        key !== "destinations" &&
        key !== "roomTypes" &&
        key !== "shipIds" &&
        key !== "details"
      ) {
        queryString += `&${filterConversion[key]}=${value}`;
      } else {
        let values;
        if (typeof filters[key] === "object") {
          values = filters[key];
        } else {
          values = filters[key].split(",");
        }
        values.forEach((arrValue) => {
          queryString += `&${filterConversion[key]}=${arrValue}`;
        });
      }
    });
  if (Object.keys(sort).length) {
    queryString += getSort(sort);
  }
  if (queryString) {
    queryString = `?${queryString.slice(1)}`;
  }
  return queryString;
};

export default getAPIQuery;
